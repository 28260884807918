// src/App.js

import React from 'react';
import ReactGa from 'react-ga4';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import UnenagudeSeletaja from './components/UnenagudeSeletaja';
import MisOnUni from './components/MisOnUni';
import UnenagudeTolgendamisest from './components/UnenagudeTolgendamisest';
import KuidasParemMagada from './components/KuidasParemMagada';
import KuidasUndMeeldeJatta from './components/KuidasUndMeeldeJatta';
import Meist from './components/Meist';
import PrivaatsusPoliitika from './components/PrivaatsusPoliitika';
import KupsistePoliitika from './components/KupsistePoliitika';
import Kontakt from './components/Kontakt';
import Footer from './components/Footer';
import Login from './components/Login'; 
import SignUp from './components/SignUp'; 
import { AuthProvider } from './context/AuthContext';
import './App.css';  
import Profile from './components/Profile';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

function App() {
  ReactGa.initialize('G-7VVR6L5DPG');
 
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LcNlIgqAAAAAMUjiQlCA7-g7fUnbw9NEYXR742j"
    >
    <AuthProvider>
      <Router>
       <div className="page-container">  
      <Header />
        <Routes>
            <Route path="/" element={<UnenagudeSeletaja />} />
            <Route path="/mis-on-uni" element={<MisOnUni />} />
            <Route path="/kuidas-unenagusid-seletada" element={<UnenagudeTolgendamisest />} />
            <Route path="/kuidas-parem-magada" element={<KuidasParemMagada />} />
            <Route path="/kuidas-und-meelde-jatta" element={<KuidasUndMeeldeJatta />} />
            <Route path="/meist" element={<Meist />} />
            <Route path="/privaatsus-poliitika" element={<PrivaatsusPoliitika />} />
            <Route path="/kupsiste-poliitika" element={<KupsistePoliitika />} />
            <Route path="/kontakt" element={<Kontakt />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/profile" element={<Profile />} />
          </Routes>
       <Footer />
       </div>
      </Router>
     
      </AuthProvider>
      </GoogleReCaptchaProvider>
      
    
  );
}

export default App;
